/*
    封装axios
    设置拦截器
    设置默认地址
*/
import axios from "axios";
// import qs from "qs";
import local from "@/utils/local"
import session from "@/utils/session"
import router from "@/router";
import { Toast } from 'vant';





//设置默认地址
//axios.defaults.baseURL = "http://47.108.178.105:8080"
axios.defaults.baseURL = "https://www.seooocookie.cn"


axios.defaults.headers.post["Content-Type"] = "application/json";

let isShowLoading = true

export default {
    //封装get请求
    changeShowLoadingT() {
        isShowLoading = true
    },
    changeShowLoadingF() {
        isShowLoading = false
    },

    get(url, params) {
        // console.log(isShowLoading)
        let getListLoading


        // console.log(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params
            }).then((res) => {
                if (getListLoading) {
                    getListLoading.close()
                }

                resolve(res.data);
            }).catch((erro) => {
                if (getListLoading) {
                    getListLoading.close()
                }
                reject(erro);
            })
        })
    },
    // 封装post请求

    post(url, params) {
        // debugger
        // console.log(isShowLoading)
        let getListLoading
        // console.log(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.post(url, params).then((res) => {

                if (getListLoading) {
                    getListLoading.close()
                }
                resolve(res.data);
            }).catch((erro) => {
                if (getListLoading) {
                    getListLoading.close()
                }
                reject(erro);
            })
        })
    },
}

// 设置请求拦截器
axios.interceptors.request.use((config) => {
    //在本地取出token

    let token = local.get('tk') || session.get('tk');
    //如何token存在，就设置在请求头中
    if (token) {
        config.headers.userToken = token;
    }
    return config
})

// //设置响应拦截
axios.interceptors.response.use((config) => {
    let {
        code,
        msg,
        message
    } = config.data;

    //如果code和msg不存在说明访问的是列表数据
   
    if (code !== undefined && message !== undefined) {
        if (code == 0) {
            // Message({
            //     type: 'success',
            //     message: msg
            // })
        } else if (code == '00' || code == '11' || code == '2000') {
            //不做操作
        }
        else if (code == 401 || code == 600) {
            //跳转至登陆页面

            router.push('/index')
            //清空数据
            local.clear()
        } else {
            Toast.fail(message);
        }
        
    }
    return config
}, (res) => {

    //如果响应后拿到的code为401，说明是无效的token 退出登录
    if (res.response.data.code == 401) {
        //跳转至登陆页面
        router.push('/index')
        //清空数据
        local.clear()
    }
})